import React from 'react'
import { Link } from 'gatsby'
import { getPublicImageTransformationUrl } from '../../utils'
import Transformation from '../../types/Transformation'

interface Props {
  content: {
    id: string
    title: string
    path: {
      alias: string
    }
    field_date?: string
    body?: {
      summary: string
    }
    relationships?: {
      field_image?: Array<{
        thumbnail: {
          alt: string
        }
        relationships?: {
          field_media_image?: {
            uri?: {
              value?: string
            }
          }
        }
      }>
    }
  }
}

const ContentPreview = ({ content }: Props) => {
  const image =
    content?.relationships?.field_image?.[0]?.relationships?.field_media_image?.uri?.value ??
    'public://2021-08/pexels-lukas-296301.jpg'
  const imageTransformations: Transformation[] = [
    {
      operation: 'scale',
      parameters: {
        width: 600,
      },
    },
  ]

  return (
    <div key={content?.id} className="flex flex-col rounded-lg shadow-lg overflow-hidden">
      <Link to={content?.path?.alias} className="">
        <div className="flex-shrink-0">
          <img
            className="h-48 w-full object-cover"
            src={getPublicImageTransformationUrl(image, imageTransformations)}
            alt={content?.relationships?.field_image?.[0]?.thumbnail?.alt ?? ''}
            loading="lazy"
          />
        </div>
        <div className="px-6 mt-2">
          <time className="text-gray-600" dateTime={content.field_date}>
            {content.field_date}
          </time>
        </div>
        <div className="flex-1 bg-white px-6 py-6 flex flex-col justify-between">
          <div className="flex-1">
            <div className="block">
              <p className="text-xl font-semibold text-gray-900 hover:underline focus:underline">
                {content?.title}
              </p>
              <p className="mt-3 text-base text-gray-500">{content?.body?.summary}</p>
            </div>
          </div>
        </div>
      </Link>
    </div>
  )
}

export default ContentPreview
