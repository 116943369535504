import React, { useContext } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { LanguageContext } from '../../../context/LanguageContext'
import ContentPreview from '../../ContentPreview'
import GridContainer from '../../GridContainer'
import RenderIf from '../../RenderIf'
import {
  Node__Specialty,
  Query,
  ParagraphSpecialtiesListingFieldsFragment,
} from '../../../types/generated'

export const ParagraphSpecialtiesListingFields = graphql`
  fragment ParagraphSpecialtiesListingFields on paragraph__specialties_listing {
    __typename
    id
    field_title
  }
`

const ParagraphSpecialtyListing = (props: ParagraphSpecialtiesListingFieldsFragment) => {
  const { field_title: fieldTitle } = props
  const { langcode } = useContext(LanguageContext)

  const data = useStaticQuery<Query>(graphql`
    {
      allNodeSpecialty(sort: { fields: field_order, order: ASC }) {
        nodes {
          id
          title
          langcode
          path {
            alias
          }
          body {
            summary
          }
          relationships {
            field_image {
              thumbnail {
                alt
              }
              gatsbyImageData(width: 600, aspectRatio: 2)
              relationships {
                field_media_image {
                  uri {
                    value
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const allNodeSpecialtyNodes = data?.allNodeSpecialty?.nodes
  // @todo: remove any type
  const currentNodes = allNodeSpecialtyNodes?.filter(
    (specialty: Node__Specialty) => specialty.langcode === langcode,
  )

  return (
    <section className="max-w-7xl">
      <RenderIf condition={!!fieldTitle}>
        <h1 className="font-bold text-center text-3xl mb-24">{fieldTitle}</h1>
      </RenderIf>
      <GridContainer>
        {currentNodes.map((specialty: any) => (
          <ContentPreview key={specialty.id} content={specialty} />
        ))}
      </GridContainer>
    </section>
  )
}

export default ParagraphSpecialtyListing
